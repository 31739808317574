*{
    box-sizing: border-box;
    font-family: 'Poppins', sans-serif;
}

body{
    margin: 0 auto;
    background-size: cover;
    max-width: 100%;
    min-width: 360px;
    min-height: 90vh;
}
